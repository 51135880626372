// Assuming the correct path to axiosclients module

import axios from "axios";
import axiosusers from "utils/api/axiosusers";
const sendOxxo = async (props) => {
  const {
    email,
    name,
    message,
    phoneNumber,
    orderId,
    checkedID,
    checkedCode,
    checkedName,
    checkedServer,
    checkedAcountPass,
    eventConcluded,
    subjectGift,
    clientId,
    gameId,
    status,
  } = props;

  const countryCodes = [
    { name: "Spain", number: "+34" },
    { name: "Mexico", number: "+52" },
    { name: "Argentina", number: "+54" },
    { name: "Colombia", number: "+57" },
    { name: "Chile", number: "+56" },
    { name: "Peru", number: "+51" },
    { name: "Venezuela", number: "+58" },
    { name: "Cuba", number: "+53" },
    { name: "Ecuador", number: "+593" },
    { name: "Guatemala", number: "+502" },
    { name: "El Salvador", number: "+503" },
    { name: "Honduras", number: "+504" },
    { name: "Nicaragua", number: "+505" },
    { name: "Costa Rica", number: "+506" },
    { name: "Panama", number: "+507" },
  ];

  const messageLang = [
    {
      es: "es",
      end: "Atentamente",
      Hi: "Hola",
      thanks: "Esperamos hacer más negocios",
      subject: "KokenShop: Notificación de estado de orden",
      subject2: "Kokenshop: Su pedido ha sido procesado con éxito.",
      Arr: "Todos los derechos reservados",
      pleaseClickHere: "Para comenzar con el pedido, haga clic aquí",

      button: "Confirmar tu pedido",
      checkedID:
        "Tu ID es incorrecta. Por favor ingrese el ID de actualización. Si tu ID: ",
      checkedCode:
        "Tu código es incorrecta. Por favor ingrese el código de actualización. ",
      checkedName:
        "Tu nombre es incorrecta. Por favor ingrese el nombre de actualización. ¿Es tu nombre: ",
      checkedServer:
        "Tu servidor es incorrecta. Por favor ingrese el servidor de actualización. ",
      checkedAcountPass:
        "Tu cuenta o contraseña es incorrecta. Por favor ingrese cuenta y contraseña. ",
      eventConcluded:
        "Debido a que el tiempo de carga de CP según lo estipulado ha excedido el tiempo del Evento x2, Koken Shop no puede adquirir el paquete x2. Por lo tanto, solicitamos la comprensión de los clientes y les pedimos que cambien al paquete regular. Koken Shop agradece sinceramente su cooperación.\nSi eliges el paquete estándar, elige Sí/falso.",
      contact:
        "Si tienes alguna pregunta, por favor contáctanos a través de los siguientes canales:",
      completedOrder:
        "1. Tu pedido ha sido completado. Gracias, espero que hayas tenido una buena experiencia cuando uses mi servicio. estaré feliz si me presentas mi servicio a tu amigo \n2. Para su seguridad, cambie su contraseña. Muchas gracias",
      activision:
        "\n3. Currently, Activision has policies to revoke CP from CODM players without any reason, so we recommend that you use all available CP.",
    },
    {
      en: "en",
      end: "Yours truly",
      Hi: "Hi",
      thanks: "Looking forward to do more business",
      subject: "KokenShop: Notification of order status",
      subject2: "Kokenshop: Your Order Has Been Successfully Processed.",
      Arr: "All rights reserved",
      pleaseClickHere: "To get started with Order, please click here",

      button: "Confirm your order",
      checkedID: "Your ID is incorrect. Please enter update ID. If your ID: ",
      checkedCode: "Your code is incorrect. Please enter update code. ",
      checkedName:
        "Your name is incorrect. Please enter update name. Is your name: ",
      checkedServer: "Your server is incorrect. Please enter update server. ",
      checkedAcountPass:
        "Your account or password is incorrect. Please enter account and password. ",
      eventConcluded:
        "Due to the CP loading time as stipulated has exceeded the time of the Event x2, Koken Shop cannot acquire the x2 package. Therefore, we request the understanding of customers and ask them to change to the regular package. Koken Shop sincerely thanks for your cooperation.\nIf you choose the standard package, choose Yes/false.",
      contact:
        "If you have any questions, please contact us through the following channels:",
      completedOrder:
        "1. Your order has been completed. Thanks you, i hope you had a good experience when you use my service. i will be happy if you introduce my service to your friend \n2. For your security, please change your password Thank you very much",
      activision:
        "\n3. Actualmente, Activision tiene políticas para revocar CP de los reproductores CODM sin ningún motivo, por lo que le recomendamos que utilice todos los CP disponibles.",
    },
  ];
  let iscountryCodes = false;
  let iscountryCodes2 = false;

  iscountryCodes = countryCodes.some((item) =>
    phoneNumber.substring(0, 3).includes(item.number)
  );
  iscountryCodes2 = countryCodes.some((item) =>
    phoneNumber.substring(0, 4).includes(item.number)
  );
  let checkedMessage = "";

  if (checkedCode) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].checkedCode
        : messageLang[1].checkedCode;
  }
  if (checkedServer) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].checkedServer
        : messageLang[1].checkedServer;
  }
  if (checkedAcountPass) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].checkedAcountPass
        : messageLang[1].checkedAcountPass;
  }
  if (eventConcluded) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].eventConcluded
        : messageLang[1].eventConcluded;
  }
  if (checkedName) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].checkedName
        : messageLang[1].checkedName;
  }
  if (checkedID) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].checkedID
        : messageLang[1].checkedID;
  }
  if (subjectGift) {
    checkedMessage +=
      iscountryCodes || iscountryCodes2
        ? messageLang[0].completedOrder
        : messageLang[1].completedOrder;
  }
  if (message) {
    checkedMessage += message;
  }
  const messageGame =
    gameId === 1 || gameId === 3
      ? countryCodes || countryCodes
        ? messageLang[0].activision
        : messageLang[1].activision
      : "";
  const dataSend = {
    email: email,
    name:
      iscountryCodes || iscountryCodes2
        ? messageLang[0].Hi + " " + name
        : messageLang[1].Hi + " " + name,
    message:
      checkedMessage +
      messageGame +
      `<br/><br/>${
        iscountryCodes || iscountryCodes2
          ? messageLang[0].contact
          : messageLang[1].contact
      }<br/>` +
      `<a href="https://wa.me/84794978152"target="_blank">Whatsapp</a>
<a href="https://web.telegram.org/k/#@seediri_bot"target="_blank">Telegram</a>
<a href="https://www.facebook.com/SeediriShop/"target="_blank">Facebook</a>
<a href="https://page.line.me/seediri"target="_blank">Line</a><br/>` +
      `<table class="discount" align="center" width="100%" cellpadding="0"
    cellspacing="0" role="presentation"
    style="width: 100%; -premailer-width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; background-color: #F4F4F7; margin: 0; padding: 24px; border: 2px dashed #CBCCCF;"
    bgcolor="#F4F4F7">
    <tr>
        <td align="center"
            style="word-break: break-word; font-family:  Helvetica, Arial, sans-serif; font-size: 16px;">
            <h1 class="f-fallback discount_heading"
                style="margin-top: 0; color: #333333; font-size: 22px; font-weight: bold; text-align: center;"
                align="center">${
                  iscountryCodes || iscountryCodes2
                    ? messageLang[0].pleaseClickHere
                    : messageLang[1].pleaseClickHere
                }
            </h1>
            <p class="f-fallback discount_body"
                style="font-size: 15px; line-height: 1.625; text-align: center; color: #51545E; margin: .4em 0 1.1875em;"
                align="center">${
                  iscountryCodes || iscountryCodes2
                    ? messageLang[0].thanks
                    : messageLang[1].thanks
                }
            </p>
            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                role="presentation">
                <tr>
                    <td align="center"
                        style="word-break: break-word; font-family: &quot;Nunito Sans&quot;, Helvetica, Arial, sans-serif; font-size: 16px;">
                        <a href="https://kokenshop.net/en/users/my-orders/detail/${orderId}"
                            class="f-fallback button button--green"
                            target="_blank"
                            style="color: #FFF; background-color: #22BC66; display: inline-block; text-decoration: none; border-radius: 3px; box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16); -webkit-text-size-adjust: none; box-sizing: border-box; border-color: #22BC66; border-style: solid; border-width: 10px 18px;">${
                              iscountryCodes || iscountryCodes2
                                ? messageLang[0].button
                                : messageLang[1].button
                            }</a>

                    </td>

                </tr>
            </table>

        </td>
    </tr>
</table>`,
    end:
      iscountryCodes || iscountryCodes2
        ? messageLang[0].end
        : messageLang[1].end,
    Arr:
      iscountryCodes || iscountryCodes2
        ? messageLang[0].Arr
        : messageLang[1].Arr,
    subject:
      iscountryCodes || iscountryCodes2
        ? subjectGift
          ? messageLang[0].subject2
          : messageLang[0].subject
        : subjectGift
        ? messageLang[1].subject2
        : messageLang[1].subject,
  };

  try {
    // Assuming you have a correct endpoint for sending OXXO data
    await axios.post(`https://api.kokenshop.net/auth/send-oxxo`, dataSend);
    const dataNotes = {
      orderId,
      notes: checkedMessage,
      clientId,
      status: "inProgress",
    };
    if (status === "notifications") {
      await axiosusers.post(
        `/notifications/create-notifications-cliens`,
        dataNotes
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// Assuming you want to return the sendOxxo function from the module
export { sendOxxo };
